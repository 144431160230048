export interface Page {
	PageNum: number;
	Body: string;
	Summary: string;
	HasAudio: boolean;
	HasImage: boolean;
}

export interface DataFile {
	StoryId: string;
	Age: number;
	Genre: string;
	Length: string;
	Prompt: string;
	PDFStatus: number;			//0-New, 1-Queued, 2-Generated
	Author: string;
	Title: string;
	Pages: Page[];
}

export interface User {
	Key: string;
	Email: string;
	Credits: number;
}


export class UserAuth {
	public Key: string;
	public Credits: number;
	public Name: string;

	constructor(key?: string, name?: string, credits?: number) {
		if ((key === undefined) || (name === undefined) || (credits === undefined)) {
			const ud = window.sessionStorage.getItem("userInfo");
			if (ud !== null) {
				const temp = JSON.parse(ud);
				this.Key = temp.Key;
				this.Credits = temp.Credits;
				this.Name = temp.Name;
			} else {
				this.Key = "";
				this.Credits = 0;
				this.Name = "";
			}
		} else {
			this.Key = key;
			this.Name = name;
			this.Credits = credits;
			this.Save();
		}
	};

	private Save() {
		window.sessionStorage.setItem("userInfo", JSON.stringify(this));
	}

	public get HasCredits(): boolean {
		return this.Credits > 0;
	}

	public get LoggedIn(): boolean {
		return this.Key !== "";
	}

	public static LogOut() {
		return new UserAuth("", "", 0);
	}
}