import React from 'react';
import './styles.scss';
import cross from "../../images/cross-colourondark.png"
import { i18n } from '../../i18n';

const Sponsors = () => {
	const l = new i18n("Sponsors");
	const t = (key: string) => l.t(key);

    return (
        <div className='homepage-part-4'>
            <div className='container'>
                <h2 className='heading'>
                    { t("CreatedBy") }
                </h2>

                <div>
                    <img src={cross} width="80%" alt="Capgemini logo" />
                </div>

                <h5>
                    { t("BroughtBy") }
                </h5>
            </div>

        </div>
    )
}

export default Sponsors;