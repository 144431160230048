import React from 'react'
import './styles.scss';
import capg from "../../images/svg/capg.svg";
import aws from '../../images/svg/aws.svg';
import { Button } from 'react-bootstrap';
import { i18n } from '../../i18n';

const Footer = () => {
	const l = new i18n("Footer");
	const t = (key) => l.t(key);

  return (
    <div className='footer-container'>
      <div className='row1'>
        <div className='story-craft-logo-footer'>Storycraft</div>
        <div className='footer-text'>{ t("Copyright") }</div>
        <div className='footer-text'><a href="https://communities.capgemini.com/terms" target="_blank">{ t("Terms") }</a></div>
        { /*<div className='footer-text'>Terms and Conditions</div> */ }
        <div className='footer-text'><a href="https://www.capgemini.com/privacy-notice/" target="_blank">{ t("Privacy") }</a></div>
      </div>
    </div>
  )
}

export default Footer