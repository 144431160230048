import React, { useEffect, useState } from 'react';
import { Button, Card, Form, ProgressBar } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataFile, UserAuth } from '../models';
import axios from 'axios';
import useDocumentTitle from '../useDocumentTitle';
import NavBar from '../components/navbar';
import "../styles/write.scss"
import quill from '../images/quill.png'
import yourstoryawaits from '../images/yourstoryawaits.png'
import Footer from '../components/footer';
import Spinner from '../components/spinner'
import { i18n } from '../i18n';

export default function Write() {
	const navigate = useNavigate();
	const [running, setRunning] = useState(false);
	const [story, setStory] = useState({ StoryId: {} } as DataFile);
	const [progress, setProgress] = useState(0);

	const [age, setAge] = useState(6);
	const [genre, setGenre] = useState("Comedy");
	const [length, setLength] = useState("Short");
	const [prompt, setPrompt] = useState("");

	const [searchParams] = useSearchParams();
	const [user, setUser] = useState(new UserAuth());

	const l = new i18n("Write");
	const t = (key: string) => l.t(key);
	const g = (key: string) => l.g("genres", key);
	const sl = (key: string) => l.g("length", key);
	
	useDocumentTitle("Storycraft - Write a New Story");

	let body;

	const updateProgress = (story: DataFile) => {
		let done = 0;
		let total = 1;
		for (let page of story.Pages) {
			total += 2;
			if (page.HasAudio) { done++; }
			if (page.HasImage) { done++; }
		}
		if (story.PDFStatus !== 0) {
			done++;
		}

		if (done === total) {
			setUser(UserAuth.LogOut());
			navigate("/read/" + story.StoryId);
			return;
		} else {
			window.setTimeout(() => {
				axios.get('/api/get-story/' + story.StoryId)
					.then(res => {
						const storyData: DataFile = (res.data as DataFile);
						updateProgress(storyData);
					});
			}, 1000);
		}

		setProgress(Math.round((done / total) * 100));
		setStory(story);
	}

	const startStory = () => {
		if (prompt === "") {
			alert(t("ErrorPrompt"));
		}

		const storyRequest = {
			key: user.Key,
			age: age,
			genre: genre,
			storyLength: length,
			prompt: prompt
		};

		setRunning(true);
		axios.post('/api/new-story', storyRequest)
			.then(res => {
				const storyData: DataFile = (res.data as DataFile);
				updateProgress(storyData);
			});
	}

	useEffect(() => {
		if (!user.LoggedIn) {
			navigate("/register", {replace: true});
		}
	
		if (!user.HasCredits) {
			navigate("/register", {replace: true});
		}
	});

	
	if (running) {
		let message = t("StateWriting") + "...";
		if (progress > 0) {
			if (progress > 75) {
				message = t("StateReading") + "..."
			} else {
				message = t("StateDrawing") + "..."
			}
		}

		body = (
			<div style={{ textAlign: "center" }}>
				<div className='loading-window'>
					<br />
					<Spinner size="5rem" />
					<br />
					<h2>{t("CraftingTitle")}</h2>
					<p className='description'>{message}</p>
				</div>
			</div>
		);
	} else {
		body = (
			<Form className='write-form' onSubmit={(e) => {
				e.preventDefault();
				//doSubmit();
			}}>
				<Form.Group className='form-group' controlId="prompt">
					<Form.Label className='dark-font'>
						<span>
							{t("FormAbout")}
						</span>
					</Form.Label>
					<Form.Control type="text" value={prompt} onChange={(e) => { setPrompt(e.target.value); }} />
					<Form.Text className="text-muted">
						{ t("FormAboutHelp") }
					</Form.Text>
				</Form.Group>
				<div className='genre-length'>
					<Form.Group className='form-group' controlId="genre">
						<Form.Label>{ t("FormGenre") }</Form.Label>
						<Form.Select aria-label="Target genre" value={genre} onChange={(e) => { setGenre(e.target.value); }}>
							<option value="Comedy">{g("Comedy")}</option>
							<option value="Adventure">{g("Adventure")}</option>
							<option value="Science Fiction">{g("Science Fiction")}</option>
							<option value="Mystery">{g("Mystery")}</option>
							<option value="Fantasy">{g("Fantasy")}</option>
							<option value="Romance">{g("Romance")}</option>
							<option value="Drama">{g("Drama")}</option>
							<option value="Horror">{g("Horror")}</option>
						</Form.Select>
						<Form.Text className="text-muted">
							{t("FormGenreHelp")}
						</Form.Text>
					</Form.Group>
					<Form.Group className='form-group' controlId="length">
						<Form.Label>{t("FormLength")}</Form.Label>
						<Form.Select aria-label="Target genre" value={length} onChange={(e) => { setLength(e.target.value); }}>
							<option value="Short">{sl("Short")}</option>
							<option value="Medium">{sl("Medium")}</option>
							<option value="Long">{sl("Long")}</option>
						</Form.Select>
						<Form.Text className="text-muted">
							{ t("FormLengthHelp") }
						</Form.Text>
					</Form.Group>
				</div>


				<Form.Group className='form-group' controlId="age">
					<Form.Label>{t("FormAge")}</Form.Label>
					<Form.Control type="number" id='age' min={1} max={10} value={age} style={{ width: '20px' }} onChange={(e) => { setAge(parseInt(e.target.value)); }} />
					<Form.Text className="text-muted">
						{t("FormAgeHelp")}
					</Form.Text>
				</Form.Group>


				<div style={{ textAlign: 'center', marginTop: 15 }}>
					<Button variant="secondary" disabled={prompt === "" || !age} onClick={startStory} >
						{t("FormButton")}
					</Button>
				</div>
				<img src={quill} className="quill-img" alt="Quill and ink" />

			</Form>
		);
	}
	

	return (
		<div>
			<NavBar />
			<div className="write-container" style={{ marginLeft: "auto", marginRight: "auto" }}>

				<div className='outer-border'>
					<Card className='write-card'>
						<Card.Body style={{padding: '12px'}}>

							<div className='write-page-grid'>
								<img src={yourstoryawaits} width="600" height="808" alt="Your story awaits" />
								<div className='form-container'>
									{!running && <h3 style={{ color: '#0C1339', width: '100%', marginTop: '0' }}>
										{t("Title")}
									</h3>}
									{body}
								</div>
							</div>
						</Card.Body>
					</Card>
				</div>


			</div>
			<Footer />
		</div>
	)
}

