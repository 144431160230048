import React from 'react';
import boxart1 from "../../images/boxart1.png"
import boxart2 from "../../images/boxart2.png"
import boxart3 from "../../images/boxart3.png"
import "./style.scss"
import { i18n } from '../../i18n';

const StoryCraftCards = () => {

	const l = new i18n("Cards");
	const t = (key: string) => l.t(key);

    return (
        <div className='homepage-part-2'>
        <div className='card-container'>
            <div className='card'>
                <h3>
                  { t("Rules") }
                </h3>
                <div style={{ minHeight: "260px" }}>
                    <img src={boxart1} alt="Box art 1" />
                </div>
                <div className='description'>
                  { t("Rules2") }
                </div>
            </div>

            <div className='card'>
                <h3>
								{ t("Pull") }
                </h3>
                <div style={{ minHeight: "260px" }}>
                    <img src={boxart2} alt="Box art 2" />
                </div>
                <div className='description'>
								{ t("Pull2") }
                </div>
            </div>

            <div className='card'>
                <h3>
									{ t("Magic") }  
                </h3>
                <div style={{ minHeight: "230px", paddingTop: "30px" }}>
                    <img src={boxart3} alt="Box art 3" />
                </div>
                <div className='description'>
								{ t("Magic2") }
                </div>
            </div>
        </div>
    </div>
    )
}

export default StoryCraftCards;