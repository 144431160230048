import { Button, Card } from 'react-bootstrap';
import NavBar from '../components/navbar';
import "../styles/write.scss"
import Footer from '../components/footer';
import { i18n } from '../i18n';
import { Fragment } from 'react';

export default function SetLang() {
	const lang = new i18n("SetLang");

	//console.log(lang);

	const setLangCode = (langCode: string) => {
		i18n.SetLangCode(langCode);
		window.location.reload();
	};

	const langs = i18n.GetLangs();

	return (
		<div>
			<NavBar />
			<div className="write-container" style={{ marginLeft: "auto", marginRight: "auto" }}>

				<div className='outer-border'>
					<Card className='write-card'>
						<Card.Body>
							<div className='write-page-grid'>
								<div style={{width: '100%', textAlign: 'center'}}>
									<h3 style={{ color: '#0C1339', width: '100%', marginTop: '0' }}>{ lang.t("Title")}</h3>
									<div style={{fontSize: 20 }}>
										<p><b>{ lang.t("Prompt")}:</b></p>
										{ langs.map(r => 
											<Fragment key={r.langCode}>
												<Button onClick={() => setLangCode(r.langCode)}>{ r.name }</Button><br />
											</Fragment>
										)}
									</div>

					
									
								</div>
							</div>
						</Card.Body>
					</Card>
				</div>


			</div>
			<Footer />
		</div>
	)
}

