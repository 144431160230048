import React from 'react';
import clsx from 'clsx';
import './styles.scss';
import { i18n } from '../../i18n';

const StoryCard = ({size, data}) => {
	const l = new i18n("StoryCard");
	const t = (key) => l.t(key);
	const g = (key) => l.g("genres", key);
	const sl = (key) => l.g("length", key);

    const getTitle = () => {
        if (data?.Title.length > 56){
            return data?.Title.slice(0,56) + " ...";
        } else {
            return data?.Title;
        }
    }

    const Capitalize = (str) => {
        if (str.length > 2)
            return (str.charAt(0).toUpperCase() + str.slice(1));
        else return str;
    }

    return (
        <div className={clsx(['story-card__container', size])}>
            <img className={clsx(['image', size])} src={ `/assets/stories/${data.StoryId}/images/0.png` } alt="Book Page" />
            <div className={clsx(['info', size])}>
            <div className='genre_container'>
                    <div className='genre'>{ g(Capitalize(data?.Genre))}</div>
                    <div className='genre'>{ sl(Capitalize(data?.Length))}</div>
                    <div className='genre'>{`${t("Age")} ${data?.Age}+`}</div>
                </div>
            <div className={clsx(['story__title', size])}>{getTitle()}</div>    
            <a className='read-more' href={`/read/${data.StoryId}`}>{ t("ReadNow") } 🡒</a>    
            </div>
        </div>
    )
}

export default StoryCard;