import { Card } from 'react-bootstrap';
import useDocumentTitle from '../useDocumentTitle';
import NavBar from '../components/navbar';
import "../styles/register.scss"
import Footer from '../components/footer';
import mysticalcreatures from '../images/mysticalCreatures.png'
import { i18n } from '../i18n';

export default function About() {
	useDocumentTitle("Storycraft - About");
	const l = new i18n("About");
	const t = (key: string) => l.t(key);
	
	return (
		<div>
			<NavBar />
			<div className='register-container'>
				<div className='outer-border'>
					<Card className="register-card" style={{ float: "left" }}>
						<Card.Header>
							<h2>{t("Title")}</h2>
						</Card.Header>
						<br />
						<img src={mysticalcreatures} height="60" alt='mystical creatures icons' />
						<br />
						<Card.Body className='description'>
							<div dangerouslySetInnerHTML={{ __html: t("Body") }}></div>
							<table width="100%">
								<tbody>
									<tr>
										<td colSpan={3}>
											<b>{t("Team")}</b>
										</td>
									</tr>
									<tr>
										<td>Wenjuan Chen</td>
										<td>Venkat Ramana Reddy Marri</td>
										<td>Karpagapriya Meyappan</td>
									</tr>
									<tr>
										<td>Neelima Nair</td>
										<td>Allan Quiaoit</td>
										<td>Richard Wilkinson</td>
									</tr>
									<tr><td>&nbsp;</td></tr>
									<tr>
										<td colSpan={3}>
											<b>Frog Design</b>
										</td>
									</tr>
									<tr>
										<td>Natalie Michalski</td>
										<td>Dzaky Rosihan</td>
										<td>Michelle Owen</td>
									</tr>
									<tr>
										<td>Ashily Devasia</td>
										<td>Jennifer Kouch</td>
										<td>Julie Raoux</td>
									</tr>
									<tr><td>&nbsp;</td></tr>
									<tr>
										<td colSpan={3}>
											<b dangerouslySetInnerHTML={{ __html: t("UI") } }></b>
										</td>
									</tr>
									<tr>
										<td>Akashdeep Sarin</td>
										<td>Sanchitha Sharma Ranganathaiah</td>
									</tr>
									<tr><td>&nbsp;</td></tr>
									<tr>
										<td colSpan={3}>
											<b>{t("Executive")}</b>
										</td>
									</tr>
									<tr>
										<td>Zack Levy</td>
										<td>Michelle Perkins</td>
									</tr>
								</tbody>
							</table>
							<br />
							<b>{t("Design")}</b>
							<ul>
								<li><a href="/images/architecture/infra.png" target="_blank">{t("Architecture")}</a></li>
								<li><a href="/images/architecture/flow1.png" target="_blank">{t("Flows1")}</a></li>
								<li><a href="/images/architecture/flow2.png" target="_blank">{t("Flows2")}</a></li>
								<li><a href="/images/architecture/flow3.png" target="_blank">{t("Generation")}</a></li>
							</ul>
						</Card.Body>
					</Card>
				</div>
			</div>
			<Footer />
		</div>
	)
}

