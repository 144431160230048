import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useDocumentTitle from '../useDocumentTitle';
import NavBar from '../components/navbar';
import "../styles/write.scss"
import Footer from '../components/footer';
import Spinner from '../components/spinner'
import { UserAuth } from '../models';
import { i18n } from '../i18n';

export default function Login() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [running, setRunning] = useState(false);
	const [doAuto, setDoAuto] = useState(true);
	const [keyIn, setKeyIn] = useState("");

	const l = new i18n("Login");
	const t = (key: string) => l.t(key);

	const [user, setUser] = useState(new UserAuth());
	useDocumentTitle("Storycraft - Account");

	const doLogin = (key: string) => {
		setRunning(true);
				
		axios.post('/api/validate-user', { key: key })
		.then(res => {
			setRunning(false);
			setUser(new UserAuth(res.data.key, res.data.name, res.data.credits));
		})
		.catch(err => {
			alert(t("ErrorNoLogin"));
			console.log(err);
			setRunning(false);
		});
	};

	//Check if we are already logged in
	if (!running) {
		console.log('running');
		if (!user.LoggedIn) {
			//not logged in so try to get from querystring
			let userKey = searchParams.get("u");
			if ((userKey !== undefined) && (userKey !== null)) {
				//It's in the querystring, so do the login
				if (doAuto) {
					setDoAuto(false);
					doLogin(userKey);
				}
			}
		}
	}

	return (
		<div>
			<NavBar />
			<div className="write-container" style={{ marginLeft: "auto", marginRight: "auto" }}>

				<div className='outer-border'>
					<Card className='write-card'>
						<Card.Body>
							<div className='write-page-grid'>
								<div style={{width: '100%', textAlign: 'center'}}>
									<h3 style={{ color: '#0C1339', width: '100%', marginTop: '0' }}>{t("Title")}</h3>

									{running && 
										<div className='loading-window' style={{marginTop: 10}}>
											<br />
											<Spinner size="5rem" />
											<br />
											<h2>{t("LoggingIn")}</h2>											
										</div>
									}

									{(!running && (!user.LoggedIn)) && <>
											<Form.Group className='form-group' controlId="Key">
												<Form.Label className='dark-font' style={{fontSize: '24px'}}>
													<span>
														{t("UserKey")}:
													</span>
												</Form.Label><br/>
												<Form.Control type="text" style={{ width: 800 }} value={keyIn} onChange={(e) => { setKeyIn(e.target.value); }} />
											</Form.Group>					
											<div style={{ textAlign: 'center', marginTop: 15 }}>
												<Button variant="secondary" onClick={() => doLogin(keyIn) } >
													{t("ButtonLogin")}
												</Button>
											</div>	
										</>
									}

									{(!running && (user.LoggedIn)) && 
										<div style={{fontSize: 20 }}>
											<p><b>{t("LoggedInAs")}: </b>{user.Name}</p>
											<p><b>{t("Credits")}: </b>{user.Credits}</p>
											<div style={{ textAlign: 'center', marginTop: 15 }}>
												<Button variant="secondary" onClick={() => setUser(UserAuth.LogOut()) } >
													{t("ButtonLogout")}
												</Button>
											</div>	
										</div>
									}
									
								</div>
							</div>
						</Card.Body>
					</Card>
				</div>


			</div>
			<Footer />
		</div>
	)
}

