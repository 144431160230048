
import axios from 'axios';
import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/navbar';
import Spinner from '../components/spinner';
import StoryCard from '../components/storycard';
import '../styles/library.scss';
import useDocumentTitle from '../useDocumentTitle';
import { UserAuth } from '../models';
import Footer from '../components/footer';
import { i18n } from '../i18n';


interface LibraryData {
	Stories: StorySummary[];
}

interface StorySummary {
	StoryId: string;
	NumPages: number;
	Age: number;
	Genre: string;
	Length: string;
	Title: string;
	Author: string;
}

export default function Library() {
	const [libData, setLibData] = useState({ Stories: [] } as LibraryData);
	const [searchStr, setSearchStr] = useState('');
	const [length, setLength] = useState('Any');
	const [age, setAge] = useState(1);
	const [genre, setGenre] = useState('Any');
	const [myStories, setMyStories] = useState(false);

	const [user, setUser] = useState(new UserAuth());
	
	const l = new i18n("Library");
	const t = (key: string) => l.t(key);
	const g = (key: string) => l.g("genres", key);
	const sl = (key: string) => l.g("length", key);


	useDocumentTitle("Storycraft - Library");

	if (libData.Stories.length === 0) {
		//Load the data
		axios.get('/api/get-library', {
			headers: {
					'sc-language': i18n.GetLangCode()
				}
			})
			.then(res => {
				setLibData(res.data);
			});
		return (
			<div style={{
				marginTop: '200px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				gap: '40px'
			}}>

				<NavBar />
				<Spinner size="10em" />
				<h2>{ t("Loading") }</h2>
			</div>
		);
	}

	//filter the results

	const results: StorySummary[] = [];
	for (var summary of libData.Stories) {
		let include = true;

		if (searchStr !== '') {
			if (!summary.Title.includes(searchStr)) {
				include = false;
			}
		}

		if (genre !== 'Any') {
			if (summary.Genre.toLowerCase() !== genre.toLowerCase()) {
				include = false;
			}
		}

		if (length !== 'Any') {
			if (summary.Length.toLowerCase() !== length.toLowerCase()) {
				include = false;
			}
		}

		if (age > summary.Age) {
			include = false;
		}

		if (myStories && (user.Key !== summary.Author)) {
			include = false;
		}

		if (include) {
			results.push(summary);
		}
	}

	return (
		<div className='library__container' >
			<NavBar />

			<div className='library-title'>{ t("Title") }</div>
			<Form onSubmit={(e) => {
				e.preventDefault();
				//doSubmit();
			}}>
				<Container className='library__selection'>
					<Row>
						<Col><Form.Label>{ t("Search") }:</Form.Label>
							<Form.Control placeholder={ t("SearchPlaceHolder") } type="text" value={searchStr} onChange={(e) => { setSearchStr(e.target.value); }} /></Col>
						<Col>
							<Col><Form.Label>{ t("Age") }:</Form.Label>
								<Form.Control type="number" min={1} max={99} value={age} onChange={(e) => { setAge(parseInt(e.target.value)); }} /></Col>

							<Col><Form.Label>{ t("Genre") }:</Form.Label>
								<Form.Select aria-label="Target genre" value={genre} onChange={(e) => { setGenre(e.target.value); }}>
									<option value="Any">{ g("Any") }</option>
									<option value="Comedy">{ g("Comedy") }</option>
									<option value="Adventure">{ g("Adventure") }</option>
									<option value="Science Fiction">{ g("Science Fiction") }</option>
									<option value="Mystery">{ g("Mystery") }</option>
									<option value="Fantasy">{ g("Fantasy") }</option>
									<option value="Romance">{ g("Romance") }</option>
									<option value="Drama">{ g("Drama") }</option>
									<option value="Horror">{ g("Horror") }</option>
								</Form.Select></Col>

							<Col><Form.Label>{ t("Length") }:</Form.Label>

								<Form.Select aria-label="Target genre" value={length} onChange={(e) => { setLength(e.target.value); }}>
									<option value="Any">{ sl("Any") }</option>
									<option value="Short">{ sl("Short") }</option>
									<option value="Medium">{ sl("Medium") }</option>
									<option value="Long">{ sl("Long") }</option>
								</Form.Select>
							</Col>

							{ user.LoggedIn && 
								<Col>
									<Form.Check
										type="switch"
										id="custom-switch"
										label={ t("ByYou") }
										checked={ myStories }
										onChange={(e) => { setMyStories(e.target.checked); }}
									/>
								</Col>
							}
						</Col>
					</Row>
				</Container>
			</Form>
			<div className='library-stories__grid'>
				{results.map(book => <StoryCard size="small" data={book} />
				)}
			</div>
			<Footer />
		</div>

	);

}