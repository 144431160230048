import React, { Component } from 'react'
import { Navigate, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { Page, DataFile } from '../models';
import { Button } from 'react-bootstrap';
import ReactGA from "react-ga4";
import axios from 'axios';
import useDocumentTitle from '../useDocumentTitle';
import NavBar from '../components/navbar';
import Spinner from '../components/spinner';
import "../styles/readbook.scss";
import CL from "../images/svg/chevron-left.svg";
import CR from "../images/svg/chevron-right.svg";
import topImg from "../images/text-section-top.png";
import bottomImg from "../images/text-section-bottom.png";
import { i18n } from '../i18n';

interface readProps {
	storyid: string;
	navigate: NavigateFunction;
}

interface readState {
	story: DataFile;
}

class ReadbookClass extends Component<readProps, readState> {

	state = {
		story: {
			StoryId: "",
			Age: 0,
			Genre: "",
			Length: "",
			Prompt: "",
			PDFStatus: 0,
			Author: "",
			Title: "",
			Pages: ([] as Page[])
		},
		pageNum: 0
	}

	audioPlayers: HTMLAudioElement[];

	playAll: boolean = false;

	from: number = 0;
	l1?: HTMLDivElement;
	l2?: HTMLDivElement;
	r1?: HTMLDivElement;
	r2?: HTMLDivElement;
	flow: number = 0;

	animating: boolean = false;

	constructor(props: readProps) {
		super(props);
		this.audioPlayers = [];
	}

	isPlaying(): boolean {
		for (let i = 0; i < this.audioPlayers.length; i++) {
			if (!this.audioPlayers[i].paused) {
				return true;
			}
		}
		return false;
	}

	loadStory(storyId: string) {
		axios.get('/api/get-story/' + storyId, {
			headers: {
					'sc-language': i18n.GetLangCode()
				}
			})
			.then(res => {
				const storyObj: DataFile = res.data as DataFile;
				//Check if the story is valid
				if (storyObj.StoryId === "") {
					this.props.navigate("/");
					return;
				}

				if (storyObj.StoryId !== this.props.storyid) {
					this.props.navigate("/read");
					return;
				}

				//Load the audio players
				this.audioPlayers = [];
				for (let i = 0; i < storyObj.Pages.length; i++) {
					const audioPlayer = new Audio();
					audioPlayer.src = `/assets/stories/${storyId}/audio/${i}.mp3`;
					audioPlayer.load();
					audioPlayer.pause();
					audioPlayer.onended = () => {
						if (this.playAll) {
							this.gotoPage(i + 1);
						}
					}
					this.audioPlayers[i] = audioPlayer;
				}

				this.setState({ story: storyObj }, () => {
					this.initPage();
				});
			});
	}

	updateControls() {

		// (document.getElementById("previousBtn")! as HTMLButtonElement).disabled = !this.canPrevious();

		// const previousBtn = (document.getElementById("previousBtn")! as HTMLButtonElement);
		// previousBtn.disabled = !this.canPrevious();
		// previousBtn.className = (previousBtn.disabled ? "btn btn-secondary" : "btn btn-success");

		// const nextBtn = (document.getElementById("nextBtn")! as HTMLButtonElement);
		// nextBtn.disabled = !this.canNext();
		// nextBtn.className = (nextBtn.disabled ? "btn btn-secondary" : "btn btn-success");

		// (document.getElementById("pageNum")! as HTMLSpanElement).innerText = (this.state.pageNum + 1).toString();

		(document.getElementById("playOne")! as HTMLButtonElement).disabled = this.isPlaying();
		(document.getElementById("playAll")! as HTMLButtonElement).disabled = this.isPlaying();
		(document.getElementById("stopPlay")! as HTMLButtonElement).disabled = !this.isPlaying();
	}

	stopAll(resetAll: boolean = false) {
		for (let i = 0; i < this.audioPlayers.length; i++) {
			this.audioPlayers[i].pause();
			this.audioPlayers[i].currentTime = 0;
		}
		if (resetAll) {
			this.playAll = false;
		}
		this.updateControls();
	}

	startPlayback() {
		if (!this.isPlaying()) {
			this.audioPlayers[this.state.pageNum].play();

			if (!this.playAll) {
				ReactGA.event({
					category: "ReadBook",
					action: "ReadSinglePage"
				});
			}
		}
		this.updateControls();
	}

	startPlaybackAll() {
		if (!this.isPlaying()) {
			this.audioPlayers[this.state.pageNum].play();
			this.playAll = true;
			this.updateControls();

			ReactGA.event({
				category: "ReadBook",
				action: "ReadAllPages"
			});
		}
	}

	hidePage(pageNum: number) {
		document.getElementById("page_left_" + pageNum)!.style.display = "none";
		document.getElementById("page_right_" + pageNum)!.style.display = "none";
	}

	showPage(pageNum: number) {
		document.getElementById("page_left_" + pageNum)!.style.display = "";
		document.getElementById("page_right_" + pageNum)!.style.display = "";
	}

	initPage() {
		this.stopAll(true);
		for (let i = 0; i <= this.state.story.Pages.length; i++) {
			this.hidePage(i);
		}
		this.setState((state) =>{ return({...state, pageNum: 0}) })
		this.showPage(0);
	}

	gotoPage(page: number) {
		if (this.animating) {
			return;

		}
		this.stopAll();

		this.from = this.state.pageNum;

		if (page > this.state.pageNum) {
			this.l1 = (document.getElementById("page_left_" + this.from) as HTMLDivElement);
			this.l2 = (document.getElementById("page_left_" + page) as HTMLDivElement);
			this.r1 = (document.getElementById("page_right_" + this.from) as HTMLDivElement);
			this.r2 = (document.getElementById("page_right_" + page) as HTMLDivElement);

			this.showPage(page);
			this.setState((state) =>{ return({...state, pageNum: page}) })

			//From page
			this.l2.style.zIndex = "20";
			this.l1.style.zIndex = "10";

			this.r1.style.zIndex = "20";
			this.r2.style.zIndex = "10";

			this.l2.style.transform = "scaleX(0)";

			this.flow = 100;
			window.requestAnimationFrame(() => {
				this.animating = true;
				this.pageAnimateForward();
			});
		}
		if (page < this.state.pageNum) {
			this.l1 = (document.getElementById("page_left_" + this.from) as HTMLDivElement);
			this.l2 = (document.getElementById("page_left_" + page) as HTMLDivElement);
			this.r1 = (document.getElementById("page_right_" + this.from) as HTMLDivElement);
			this.r2 = (document.getElementById("page_right_" + page) as HTMLDivElement);

			this.showPage(page);
			this.setState((state) =>{ return({...state, pageNum: page}) })

			//From page
			this.l1.style.zIndex = "20";
			this.l2.style.zIndex = "10";

			this.r2.style.zIndex = "20";
			this.r1.style.zIndex = "10";

			this.r2.style.transform = "scaleX(0)";

			this.flow = -100;
			window.requestAnimationFrame(() => {
				this.animating = true;
				this.pageAnimateBackwards();
			});
		}
	}

	pageAnimateDone() {
		this.animating = false;
		if (this.state.pageNum === this.state.story.Pages.length) {
			this.stopAll(true);
		}
		if (this.playAll) {
			window.setTimeout(() => {
				this.startPlayback();
			}, 1000);
		}

		this.updateControls();
	}

	pageAnimateForward() {
		this.flow -= 10;

		if (this.flow > 0) {
			this.r1!.style.transform = "scaleX(" + (this.flow / 100) + ")";
			this.l2!.style.transform = "scaleX(0)";
		} else {
			const newFlow = (0 - this.flow) / 100;
			this.r1!.style.transform = "scaleX(0)";
			this.l2!.style.transform = "scaleX(" + newFlow + ")";
		}

		if (this.flow < -100) {
			this.hidePage(this.from);
			this.l2!.style.transform = "";
			this.r2!.style.transform = "";
			this.l1!.style.transform = "";
			this.r1!.style.transform = "";

			this.l1!.style.zIndex = "10";
			this.r1!.style.zIndex = "10";
			this.l2!.style.zIndex = "10";
			this.r2!.style.zIndex = "10";

			this.pageAnimateDone();
		} else {
			window.requestAnimationFrame(() => {
				this.pageAnimateForward();
			});
		}
	}

	pageAnimateBackwards() {
		this.flow += 10;

		if (this.flow > 0) {
			this.r2!.style.transform = "scaleX(" + (this.flow / 100) + ")";
			this.l1!.style.transform = "scaleX(0)";
		} else {
			const newFlow = (0 - this.flow) / 100;
			this.r2!.style.transform = "scaleX(0)";
			this.l1!.style.transform = "scaleX(" + newFlow + ")";
		}

		if (this.flow > 100) {
			this.hidePage(this.from);
			this.l2!.style.transform = "";
			this.r2!.style.transform = "";
			this.l1!.style.transform = "";
			this.r1!.style.transform = "";

			this.l1!.style.zIndex = "10";
			this.r1!.style.zIndex = "10";
			this.l2!.style.zIndex = "10";
			this.r2!.style.zIndex = "10";


			this.pageAnimateDone();
		} else {
			window.requestAnimationFrame(() => {
				this.pageAnimateBackwards();
			});
		}
	}

	canNext(): boolean {
		return (this.state.pageNum < this.state.story.Pages.length);
	}

	next() {
		if (this.state.pageNum < this.state.story.Pages.length) {
			this.gotoPage(this.state.pageNum + 1);
		}
	}

	canPrevious(): boolean {
		return (this.state.pageNum > 0);
	}

	previous() {
		if (this.state.pageNum > 0) {
			this.gotoPage(this.state.pageNum - 1);
		}
	}

	render() {
		const l = new i18n("ReadStory");
		const t = (key: string) => l.t(key);

		const storyId = this.props.storyid;

		//Check if we need to load the story and components, also called if the storyID changes
		if (storyId !== this.state.story.StoryId) {
			this.loadStory(storyId);
		}

		if (this.state.story.Pages.length === 0) {
			return (
				<div style={{
					marginTop: '200px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					gap: '40px'
				}}>

					<NavBar />
					<Spinner size="10em" />
					<h2>{ t("Loading") }</h2>
				</div>
			);
		}

		let pdfButton = (<></>);

		console.log(this.state.story);

		if (this.state.story.PDFStatus === 1) {
			pdfButton = (
				<Button style={buttonStyle} variant='success' onClick={() => window.location.href = `/assets/stories/${storyId}/Story.pdf` } >{ t("DownloadPDF") }</Button>
			);
		}

		console.log()

		const hasAudio = this.state.story.Pages[0].HasAudio;

		return (
			<div className='read-book__container'>
				<NavBar />
				<div className='title'>{this.state.story.Title}</div>
				<div className='book'>
					{(this.state.pageNum > 0) && <button onClick={() => this.previous()} className='previous-button'><img src={CL}/></button>}
					{(this.state.pageNum === 0) && <button className='previous-button' style={{backgroundColor: "rgba(12, 19, 57, 0)", border: "none" }}></button>}
					<div style={bookStyle}>
						{this.state.story.Pages.map((page: Page) => {
							return (
								<div style={{display: "flex"}} key={page.PageNum}>
									<div id={"page_left_" + page.PageNum} key={"page_left_" + page.PageNum} className='textPage' style={pageTextFrameStyle}>
										<img src={topImg} />
										<div className='text-section'>
											{page.Body}
										</div>
										<img src={bottomImg} />		
									</div>
									<div id={"page_right_" + page.PageNum} key={"page_right_" + page.PageNum} className='imagePage' style={pageImageFrameStyle}>
										<div style={pageImageStyle}>
											<div>
												<img src={`/assets/stories/${storyId}/images/${page.PageNum}.png`} alt="Book Page" style={{ width: 460 }} />
											</div>
											<div className='text-section-subtext'>
												{page.Summary}
											</div>
										</div>
									</div>
								</div>
							);
						})}

						<div id={"page_left_" + this.state.story.Pages.length} style={pageTextFrameStyle}>
							<img src={topImg} />
							<div className='text-section'>
								<h3 style={{ fontStyle: "italic", padding: 0, margin: 0 }}>{ t("TheEnd") }</h3>
								<p>
									{ t("StoryBy") }
								</p>
								<div style={{ textAlign: 'center' }}>
									<img style={{ paddingTop: "20px" }} src='../images/Capgemini-Logo.png' width="450" alt="Capgemini" />
								</div>
							</div>

						</div>
						<div id={"page_right_" + this.state.story.Pages.length} style={{ ...pageImageFrameStyle, padding:"16px 0px 24px 0px", boxSizing: "border-box" }}>
							<img src={topImg} />
							<div className='text-section' style={{ textAlign: "center" }}>
								<p style={{ marginTop: "40px", color: "#000000" }}>
									{ t("ImagesBy") }
								</p>
								<img src='../images/bedrock.png' style={{ width: 180 }} alt="AWS" />
								<img src='../images/aws.png' style={{ width: 180 }} alt="AWS" />
							</div>
						</div>

					</div>
					{this.canNext() && <button onClick={() => this.next()} className='next-button'><img src={CR}/></button>}
					{!this.canNext() && <button className='next-button' style={{backgroundColor: "rgba(12, 19, 57, 0)", border: "none" }}></button>}
				</div>
				<div className='page-number'>{ t("Page") } {this.state.pageNum + 1} of {this.state.story.Pages.length + 1}</div>
				<div style={{ textAlign: "center", marginTop: 10, display: (hasAudio?"":"none") }}>
					<Button id="playOne" style={buttonStyle} variant='info' onClick={() => this.startPlayback()}>{ t("ReadPage") }</Button>
					<Button id="playAll" style={buttonStyle} variant='info' onClick={() => this.startPlaybackAll()} >{ t("ReadStory") }</Button>
					<Button id="stopPlay" style={buttonStyle} variant='danger' onClick={() => this.stopAll(true)}>{ t("ReadStop") }</Button>
					{ pdfButton }
				</div>
				<div className='bottom-message'>
					{t("StoryId")}: {this.state.story.StoryId} - {t("Link")}: <a href={`https://storycraft.capgemini.studio/read/${this.state.story.StoryId}`} style={{color: "#FFFFFF"}}>https://storycraft.capgemini.studio/read/{this.state.story.StoryId}</a>
				</div>
			</div>
		)
	}
}

const buttonStyle: React.CSSProperties = {
	marginLeft: 10,
	marginRight: 10
}

const bookStyle: React.CSSProperties = {
	width: "80vw",
	height: 550,
	position: "relative",
	top: 0,
	left: 0,
	marginBottom: "30px"
}

const pageTextFrameStyle: React.CSSProperties = {
	position: "absolute",
	transformOrigin: "top right",
	width: "40vw",
	height: 550,
	left: 0,
	top: 0,
	backgroundColor: "#eeeae0",
	border: "1px solid #dddddd",
	overflow: "hidden",
	padding:"16px 0px 24px 0px",
	boxSizing: "border-box",
}

const pageImageFrameStyle: React.CSSProperties = {
	position: "absolute",
	transformOrigin: "top left",
	height: 550,
	width: "40vw",
	left: "40vw",
	top: 0,
	backgroundColor: "#eeeae0",
	overflow: "hidden"
}

const pageImageStyle: React.CSSProperties = {
	padding: 15,
	textAlign: "center",
}

export default function ReadBook() {
	const { storyid } = useParams();
	const navigate = useNavigate();
	useDocumentTitle("Storycraft - Read story #" + storyid);

	if (storyid === undefined) {
		alert("Story ID missing");
		navigate("/");
		return <></>;
	}

	return <ReadbookClass storyid={storyid} navigate={navigate} />;
}
