
export type Translation = {[key: string]: {[key: string]: string}};

export interface Language {
	langCode: string;
	name: string;
	translations: Translation;	
	meta: Translation;
}

const langData: Language[] = [
	{
		langCode: 'en',
		name: 'English',
		meta: {
			genres: {
				Any: "Any",
				Comedy: "Comedy", 
				Adventure: "Adventure", 
				"Science Fiction": "Science Fiction",
				Mystery: "Mystery",
				Fantasy: "Fantasy",
				Romance: "Romance",
				Drama: "Drama", 
				Horror: "Horror"
			},
			length: {
				Any: "Any",
				Short: "Short",
				Medium: "Medium", 
				Long: "Long"
			}
		},

		translations: {
			Home: {
				Line1: "Storycraft, a reimagination of traditional storytelling",
				Line2: "Your door to worlds of magic",
				CTAButton: "Create a story",
				PopularThisWeek: "Popular stories this week",
			},
			SetLang: {
				Title: "Storycraft Language",
				Prompt: "Please select your language"
			},
			NavBar: {
				Library: "Library",
				Write: "Write",
				About: "About",
				Language: "Language"
			},
			Footer: {
				Copyright: "Copyright © Capgemini 2023",
				Terms: "Terms of Service",
				Privacy: "Capgemini Privacy policy"
			},
			Cards: {
				Rules: "Make the rules",
				Rules2: "You choose the target age, genre, length, and, crucially, the core theme of your story.",
				Pull: "Pull it together",
				Pull2: "We apply your guidelines, utilizing Generative AI to craft both the story and its accompanying illustrations.",
				Magic: "Enjoy the magic",
				Magic2: "Experience your story through our viewer, download a PDF, or have ML-powered text-to-speech bring it to life."
			},
			StoryCard: {
				ReadNow: "Read now",
				Age: "Age"
			},
			Sponsors: {
				CreatedBy: "Storycraft has been created by",
				BroughtBy: "Storycraft is brought to you by Capgemini and powered by Amazon Web Services (AWS)."
			},
			Library: {
				Title: "Library",
				Search: "Search",
				SearchPlaceHolder: "Search title or keyword",
				Age: "Age",
				Genre: "Genre",
				Length: "Length",
				ByYou: "Written by you",
				Loading: "Loading Library"
			},
			ReadStory: {
				Loading: "Loading Story", 
				DownloadPDF: "Download PDF",
				TheEnd: "The End!",
				StoryBy: "Storycraft was designed and built by the Sydney Capgemini team.",
				ImagesBy: "Stories and images generated by Amazon Bedrock.",
				Page: "Page",
				ReadPage: "Read Page Aloud",
				ReadStory: "Read Story Aloud",
				ReadStop: "Stop",
				StoryId: "You are reading Story ID",
				Link: "You can return to this story at any time using the following link"
			},
			Write: {
				ErrorPrompt: "You must provide a prompt!",
				StateWriting: "Writing your story",
				StateReading: "Reading out loud",
				StateDrawing: "Getting artistic",
				Title: "Write a new story",
				CraftingTitle: "Crafting Story",
				FormAbout: "What should the story be about?",
				FormAboutHelp: "What should your story be about?",
				FormGenre: "What is the genre?",
				FormGenreHelp: "What kind of story should we create?",
				FormLength: "How long should it be?",
				FormLengthHelp: "Short is 3-4 pages, medium is 5-6 pages and long is 7-8 pages",
				FormAge: "How old is the reader?",
				FormAgeHelp: "Please specify the age that you would like to write a story for. This will help Storycraft write the best story with language adjusted for your reader",
				FormButton: "Create your story",
			},
			Login: {
				ErrorNoLogin: "Unable to login user at this time",
				Title: "Storycraft Account",
				LoggingIn: "Logging in",
				UserKey: "User Key",
				ButtonLogin: "Login",
				LoggedInAs: "Logged in as",
				Credits: "Credits Remaining",
				ButtonLogout: "Logout"
			},
			Register: {
				Error: "Unable to register user at this time",
				Running: "Registering User",
				Intro: `<p>
					You will need to be registered before you can write your own stories. To register, please provide your details below. After you registration, you
					will be redirected to write a story. This will grant you one story creation.
				</p>
				<p>
					Once you have verify your email address, you will be gifted three additional story credits.
				</p>`,
				Name: "Full name",
				NameError: "Please enter your full name",
				Email: "Email",
				EmailError: "Please enter a valid email",
				Company: "Company",
				CompanyError: "Please enter a valid company",
				CheckBox:`By checking this box you accept that you have read and consent to the sharing of information in accordance with 
				the <a href="https://www.capgemini.com/privacy-notice/"> Capgemini Privacy policy</a> and you understand that your 
				data will be processed by Capgemini as indicated above and described in the <a href="https://www.capgemini.com/terms-of-use/">Terms of use</a>`,
				ButtonRegister: "Register",
				ButtonHome: "Home",
				ThanksCredits: "Thanks, you are now registered and have been given trial credits. Each credit can be used to create one story. If you have not yet verified your email, you can get additional credits by using the link in your welcome email.",
				ThanksNoCredits: "Thanks for your interest in Storycraft. You don't have any credits left. To request more credits, please contact your Capgemini representitive. If you have not yet verified your email, you can get additional credits by using the link in your welcome email.",
				Title: "Write a new story",
			},
			About: {
				Title: "About Storycraft",
				Body: `<p>
				Storycraft is a Proof of Concept born from a two-hour hackathon at Capgemini offices across the Asia Pacific region in partnership with AWS. 
				The project aims to fuse modern AI/ML with the timeless art of storytelling, using Generational AI (GenAI) to transform adult concepts like 
				the water cycle into child-friendly narratives.
			</p>
			<p>
				The Sydney hackathon team, inspired by a team member's experience of storytelling to her children, conceptualized using GenAI to craft stories tailored 
				to children's interests. Their vision is to provide tools for teachers and caregivers to create engaging stories around concepts that excite children, 
				such as fire trucks exploring heat conductivity. The team's success in developing a working prototype during the hackathon led to them winning first 
				place, sparking wider enthusiasm and resulting in the current evolved version of the platform, which retains the same underlying AI logic.
			</p>
			<p>
				Ultimately, there are two key take-aways:
				<ol>
					<li>Generative AI is a powerful tool, more than just a toy, a Chatbot or a recommendation engine.</li>
					<li>Like all tools, ensure you are using the right one. Start with the problem to be solved and find the right tool.</li>
				</ol>
				Here, at Capgemini, we would love the opportunity to understand the issues you are facing and work with you to find the right solution. <br />
				For more information, or to explore your Gen AI needs, please reach out to <a href="mailto:richard.a.wilkinson@capgemini.com">Richard Wilkinson</a>.
			</p>`,
				Team: "Storycraft Sydney Team",
				UI: "User Interface &amp; Experience",
				Executive: "Executive Sponsors",
				Design: "System Design",
				Architecture: "System Architecture",
				Flows1: "User Flows 1",
				Flows2: "User Flows 2",
				Generation: "Story Generation Flow"
			}
		}
	},
	{
		langCode: 'ms',
		name: 'Melayu',
		meta: {
			genres: {
				Any: 'Mana-mana',
				Comedy: 'Komedi',
				Adventure: 'Pengembaraan',
				'Science Fiction': 'Fiksyen Sains',
				Mystery: 'Misteri',
				Fantasy: 'Fantasi',
				Romance: 'Romantik',
				Drama: 'Drama',
				Horror: 'Seram'
			},
			length: {
				Any: 'Mana-mana',
				Short: 'Pendek',
				Medium: 'Perantara',
				Long: 'Panjang'
			}
		},
		translations: {
			Home: {
				Line1: 'Storycraft, imaginasi semula penceritaan tradisional',
				Line2: 'Pintu anda ke dunia sihir',
				CTAButton: 'Buat cerita',
				PopularThisWeek: 'Cerita popular minggu ini'
			},
			SetLang: { Title: 'Bahasa Storycraft', Prompt: 'Sila pilih bahasa anda' },
			NavBar: {
				Library: 'Perpustakaan',
				Write: 'Tulis',
				About: 'Mengenai',
				Language: 'Bahasa'
			},
			Footer: {
				Copyright: 'Hak Cipta © Capgemini 2023',
				Terms: 'Syarat Perkhidmatan',
				Privacy: 'Dasar privasi Capgemini'
			},
			Cards: {
				Rules: 'Buat peraturan',
				Rules2: 'Anda memilih umur sasaran, genre, panjang, dan, yang penting, tema teras cerita anda.',
				Pull: 'Tarik bersama-sama',
				Pull2: 'Kami menerapkan garis panduan anda, menggunakan AI Generatif untuk membuat cerita dan ilustrasi yang disertakannya.',
				Magic: 'Nikmati keajaiban',
				Magic2: 'Alami cerita anda melalui penonton kami, muat turun PDF, atau minta teks ke ucapan yang dikuasakan ML menghidupkannya.'
			},
			StoryCard: { ReadNow: 'Baca sekarang', Age: 'Umur' },
			Sponsors: {
				CreatedBy: 'Storycraft telah dicipta oleh',
				BroughtBy: 'Storycraft dibawa kepada anda oleh Capgemini dan dikuasakan oleh Amazon Web Services (AWS).'
			},
			Library: {
				Title: 'Perpustakaan',
				Search: 'Cari',
				SearchPlaceHolder: 'Cari tajuk atau kata kunci',
				Age: 'Umur',
				Genre: 'Genre',
				Length: 'Panjang',
				ByYou: 'Ditulis oleh anda',
				Loading: 'Memuatkan Perpustakaan'
			},
			ReadStory: {
				Loading: 'Memuatkan Cerita',
				DownloadPDF: 'Muat turun PDF',
				TheEnd: 'Akhirnya!',
				StoryBy: 'Storycraft direka dan dibina oleh pasukan Sydney Capgemini.',
				ImagesBy: 'Cerita dan imej yang dihasilkan oleh Amazon Bedrock.',
				Page: 'Halaman',
				ReadPage: 'Baca Halaman Dengan Kuat',
				ReadStory: 'Baca Cerita dengan kuat',
				ReadStop: 'Berhenti',
				StoryId: 'Anda sedang membaca Story ID',
				Link: 'Anda boleh kembali ke cerita ini pada bila-bila masa menggunakan pautan berikut'
			},
			Write: {
				ErrorPrompt: 'Anda mesti memberikan segera!',
				StateWriting: 'Menulis cerita anda',
				StateReading: 'Membaca dengan kuat',
				StateDrawing: 'Mendapatkan artistik',
				Title: 'Tulis cerita baru',
				CraftingTitle: 'Kisah Kerajinan',
				FormAbout: 'Apa yang sepatutnya cerita itu?',
				FormAboutHelp: 'Apa yang sepatutnya cerita anda?',
				FormGenre: 'Apakah genre itu?',
				FormGenreHelp: 'Apa jenis cerita yang harus kita buat?',
				FormLength: 'Berapa lamakah ia sepatutnya?',
				FormLengthHelp: 'Pendek adalah 3-4 halaman, medium adalah 5-6 halaman dan panjang adalah 7-8 halaman',
				FormAge: 'Berapa umur pembaca?',
				FormAgeHelp: 'Sila nyatakan umur yang anda ingin menulis cerita. Ini akan membantu Storycraft menulis cerita terbaik dengan bahasa yang disesuaikan untuk pembaca anda',
				FormButton: 'Buat cerita anda'
			},
			Login: {
				ErrorNoLogin: 'Tidak dapat log masuk pengguna pada masa ini',
				Title: 'Akaun Storycraft',
				LoggingIn: 'Log masuk',
				UserKey: 'Kunci Pengguna',
				ButtonLogin: 'Log masuk',
				LoggedInAs: 'Log masuk sebagai',
				Credits: 'Baki Kredit',
				ButtonLogout: 'Log Keluar'
			},
			Register: {
				Error: 'Tidak dapat mendaftar pengguna pada masa ini',
				Running: 'Mendaftar Pengguna',
				Intro: '<p>\n' +
					' Anda perlu didaftarkan sebelum anda boleh menulis cerita anda sendiri. Untuk mendaftar, sila berikan butiran anda di bawah. Selepas anda mendaftar, anda\n' +
					' akan diarahkan untuk menulis cerita. Ini akan memberi anda satu penciptaan cerita.</p>\n' +
					' <p>\n' +
					' Sebaik sahaja anda mengesahkan alamat e-mel anda, anda akan diberi tiga kredit cerita tambahan.</p>',
				Name: 'Nama penuh',
				NameError: 'Sila masukkan nama penuh anda',
				Email: 'E-mel',
				EmailError: 'Sila masukkan e-mel yang sah',
				Company: 'Syarikat',
				CompanyError: 'Sila masukkan syarikat yang sah',
				CheckBox: 'Dengan menandakan kotak ini, anda menerima bahawa anda telah membaca dan bersetuju untuk perkongsian maklumat mengikut \n' +
					' Dasar Pri <a href="https://www.capgemini.com/privacy-notice/">vasi Capgemini</a> dan anda memahami bahawa anda \n' +
					' data akan diproses oleh Capgemini seperti yang dinyatakan di atas dan diterangkan dalam <a href="https://www.capgemini.com/terms-of-use/">Syarat penggunaan</a>',
				ButtonRegister: 'Daftar',
				ButtonHome: 'Rumah',
				ThanksCredits: 'Terima kasih, anda kini didaftarkan dan telah diberi kredit percubaan. Setiap kredit boleh digunakan untuk membuat satu cerita. Jika anda belum mengesahkan e-mel anda, anda boleh mendapatkan kredit tambahan dengan menggunakan pautan dalam e-mel selamat datang anda.',
				ThanksNoCredits: 'Terima kasih atas minat anda dalam Storycraft. Anda tidak mempunyai kredit yang tersisa. Untuk meminta lebih banyak kredit, sila hubungi representitif Capgemini anda. Jika anda belum mengesahkan e-mel anda, anda boleh mendapatkan kredit tambahan dengan menggunakan pautan dalam e-mel selamat datang anda.',
				Title: 'Tulis cerita baru'
			},
			About: {
				Title: 'Mengenai Storycraft',
				Body: '<p>\n' +
					' Storycraft adalah Bukti Konsep yang lahir daripada hackathon selama dua jam di pejabat Capgemini di seluruh rantau Asia Pasifik dengan kerjasama AWS. \n' +
					' Projek ini bertujuan untuk menggabungkan AI/ML moden dengan seni bercerita abadi, menggunakan Generational AI (GenAI) untuk mengubah konsep dewasa seperti \n' +
					' kitaran air menjadi naratif mesra kanak-kanak</p>.\n' +
					' <p>\n' +
					' Pasukan hackathon Sydney, yang diilhamkan oleh pengalaman ahli pasukan untuk bercerita kepada anak-anaknya, dikonseptualisasikan menggunakan GenAi untuk membuat cerita yang disesuaikan \n' +
					' Kepentingan kanak-kanak. Visi mereka adalah untuk menyediakan alat untuk guru dan penjaga untuk mencipta cerita menarik di sekitar konsep yang menggembirakan kanak-kanak, \n' +
					' seperti trak bomba yang meneroka kekonduksian haba. Kejayaan pasukan dalam membangunkan prototaip kerja semasa hackathon menyebabkan mereka menang terlebih dahulu \n' +
					' tempat, mencetuskan semangat yang lebih luas dan menghasilkan versi platform yang berkembang semasa, yang mengekalkan logik AI asas yang sama</p>.\n' +
					' <p>\n' +
					' Pada akhirnya, terdapat dua pengambilan utama:\n' +
					' <ol>\n' +
					' <li>AI generatif adalah alat yang kuat, lebih daripada sekadar mainan, Chatbot atau mesin cadangan.</li>\n' +
					' <li>Seperti semua alat, pastikan anda menggunakan yang betul. Mulakan dengan masalah yang akan diselesaikan dan cari alat yang betul.</li> </ol>\n' +
					' Di sini, di Capgemini, kami ingin mendapat peluang untuk memahami masalah yang anda hadapi dan bekerjasama dengan anda untuk mencari penyelesaian yang tepat. <br />\n' +
					' Untuk maklumat lanjut, atau untuk meneroka keperluan Gen AI anda, sila hubungi <a href="mailto:richard.a.wilkinson@capgemini.com">Richard Wilkinson</a></p>.',
				Team: 'Pasukan Storycraft Sydney',
				UI: 'Antara muka & Pengalaman Pengguna',
				Executive: 'Penaja Eksekutif',
				Design: 'Reka Bentuk Sistem',
				Architecture: 'Seni Bina Sistem',
				Flows1: 'Aliran Pengguna 1',
				Flows2: 'Aliran Pengguna 2',
				Generation: 'Aliran Generasi Cerita'
			}
		}
	},
	{
		langCode: 'zh',
		name: '简体中文',
		meta: {
			genres: {
				Any: '任何',
				Comedy: '喜剧',
				Adventure: '冒险',
				'Science Fiction': '科幻小说',
				Mystery: '神秘',
				Fantasy: '幻想',
				Romance: '浪漫',
				Drama: '戏剧',
				Horror: '恐怖'
			},
			length: { Any: '任何', Short: '短', Medium: '中等', Long: '长' }
		},
		translations: {
			Home: {
				Line1: 'Storycraft，对传统叙事的重新构想',
				Line2: '你通往魔法世界的大门',
				CTAButton: '创建故事',
				PopularThisWeek: '本周热门故事'
			},
			SetLang: { Title: 'Storycraft 语言', Prompt: '请选择您的语言' },
			NavBar: { Library: '图书馆', Write: '写作', About: '关于', Language: '语言' },
			Footer: {
				Copyright: '版权所有 © Capgemini 2023',
				Terms: '服务条款',
				Privacy: 'Capgemini 隐私政策'
			},
			Cards: {
				Rules: '制定规则',
				Rules2: '你可以选择目标年龄、类型、时长，至关重要的是，选择故事的核心主题。',
				Pull: '把它放在一起',
				Pull2: '我们会运用您的指导方针，利用生成式人工智能来制作故事及其附带的插图。',
				Magic: '尽情享受魔法',
				Magic2: '通过我们的查看器体验您的故事，下载 PDF，或者让机器学习支持的文字转语音变为现实。'
			},
			StoryCard: { ReadNow: '立即阅读', Age: '年龄' },
			Sponsors: {
				CreatedBy: 'Storycraft 的创建者',
				BroughtBy: 'Storycraft 是由 Capgemini 带给你并且由 AWS 提供支持。'
			},
			Library: {
				Title: '图书馆',
				Search: '搜寻',
				SearchPlaceHolder: '搜索标题或关键字',
				Age: '年龄',
				Genre: '流派',
				Length: '长度',
				ByYou: '由你写的',
				Loading: '正在加载库'
			},
			ReadStory: {
				Loading: '正在加载故事',
				DownloadPDF: '下载PDF',
				TheEnd: '结局！',
				StoryBy: 'Storycraft由悉尼Capgemini团队设计和建造。',
				ImagesBy: '由亚马逊 Bedrock 生成的故事和图片。',
				Page: '页面',
				ReadPage: '大声朗读页面',
				ReadStory: '大声朗读故事',
				ReadStop: '停下来',
				StoryId: '你正在阅读故事 ID',
				Link: '你可以随时使用以下链接返回这个故事'
			},
			Write: {
				ErrorPrompt: '你必须提供提示！',
				StateWriting: '请稍等',
				StateReading: '大声朗读',
				StateDrawing: '变得有艺术气息',
				Title: '创造一个故事',
				CraftingTitle: '故事创造中',
				FormAbout: '这个故事应该是关于什么的？',
				FormAboutHelp: '你的故事应该是关于什么的？',
				FormGenre: '类型？',
				FormGenreHelp: '我们应该创作什么样的故事？',
				FormLength: '故事的长度？',
				FormLengthHelp: '短篇是 3-4 页，中篇是 5-6 页，长篇是 7-8 页。',
				FormAge: '读者几岁了？',
				FormAgeHelp: '请指定您想写故事的年龄。这将帮助 Storycraft 根据读者调整语言来撰写最佳故事',
				FormButton: '创作你的故事'
			},
			Login: {
				ErrorNoLogin: '此时无法登录用户',
				Title: '故事世界账号',
				LoggingIn: '登录',
				UserKey: '用户密钥',
				ButtonLogin: '登录',
				LoggedInAs: '以身份登录',
				Credits: '剩余积分',
				ButtonLogout: '注销'
			},
			Register: {
				Error: '目前无法注册用户',
				Running: '注册用户',
				Intro: '<p>\n' +
					' 你需要先注册，然后才能写自己的故事。要注册，请在下面提供您的详细信息。注册后，您\n' +
					' 将被重定向来写一个故事。这将允许你创作一个故事。</p>\n' +
					' <p>\n' +
					' 验证电子邮件地址后，您将获得另外三个故事积分</p>。',
				Name: '全名',
				NameError: '请输入你的全名',
				Email: '电子邮件',
				EmailError: '请输入有效的电子邮件',
				Company: '公司',
				CompanyError: '请输入有效的公司',
				CheckBox: '勾选此框即表示您接受您已阅读并同意按照以下规定共享信息 \n' +
					' <a href="https://www.capgemini.com/privacy-notice/">Capgemini隐私政策</a>，你知道你的 \n' +
					' 数据将由Capgemini按照上述和<a href="https://www.capgemini.com/terms-of-use/">使用条款中的描述进行处理</a>',
				ButtonRegister: '注册',
				ButtonHome: '主页',
				ThanksCredits: '谢谢，您现已注册并已获得试用积分。每个积分都可用于创建一个故事。如果您尚未验证您的电子邮件，则可以使用欢迎电子邮件中的链接获得额外的积分。',
				ThanksNoCredits: '感谢您对 Storycraft 的关注。你没有剩余的积分了。如需申请更多积分，请联系您的Capgemini代表。如果您尚未验证您的电子邮件，则可以使用欢迎电子邮件中的链接获得额外的积分。',
				Title: '写一个新故事'
			},
			About: {
				Title: 'Storycraft',
				Body: '<p>\n' +
					' Storycraft是一款概念验证，源自与AWS合作在亚太地区Capgemini办事处举行的为时两小时的黑客马拉松。 \n' +
					' 该项目旨在将现代人工智能/机器学习与永恒的讲故事艺术融为一体，使用世代人工智能（GenAI）来改变成人概念，例如 \n' +
					' 水循环变成对儿童友好的叙事</p>。\n' +
					' <p>\n' +
					' 悉尼黑客马拉松团队的灵感来自于团队成员向孩子讲故事的经历，使用 GenAI 来构思量身定制故事 \n' +
					' 符合孩子的利益。他们的愿景是为教师和看护人员提供工具，让他们围绕激发孩子兴奋的概念创作引人入胜的故事， \n' +
					' 例如探测导热性的消防车。该团队在黑客马拉松期间成功开发了可行的原型，这使他们获得了第一名 \n' +
					' 位置，激发了更广泛的热情，并促成了该平台的当前演变版本，该版本保留了相同的底层人工智能逻辑</p>。\n' +
					' <p>\n' +
					' 归根结底，有两个关键要点：\n' +
					' <ol>\n' +
					' <li>生成式 AI 是一种强大的工具，不仅仅是玩具、聊天机器人或推荐引擎。</li>\n' +
					' <li>像所有工具一样，请确保使用正确的工具。从要解决的问题开始，找到合适的工具。</li></ol>\n' +
					' 在Capgemini，我们很乐意有机会了解您所面临的问题，并与您合作找到正确的解决方案。<br />\n' +
					' 如需了解更多信息或了解您的下一代人工智能需求，请联系<a href="mailto:richard.a.wilkinson@capgemini.com">理查德·威尔金森</a></p>。',
				Team: 'Storycraft 悉尼团队',
				UI: '用户界面和体验',
				Executive: '执行赞助商',
				Design: '系统设计',
				Architecture: '系统架构',
				Flows1: '用户流量 1',
				Flows2: '用户流量 2',
				Generation: '故事生成流程'
			}
		}
	},
	{
		langCode: 'ta',
		name: 'தமிழ்',
		meta: {
			genres: {
				Any: 'ஏதேனும்',
				Comedy: 'காமெடி',
				Adventure: 'சாகசம்',
				'Science Fiction': 'அறிவியல் புனைகதை',
				Mystery: 'மர்மம்',
				Fantasy: 'கற்பனை',
				Romance: 'காதல்',
				Drama: 'நாடகம்',
				Horror: 'திகில்'
			},
			length: {
				Any: 'ஏதேனும்',
				Short: 'குறுகிய',
				Medium: 'நடுத்தர',
				Long: 'நீண்ட'
			}
		},
		translations: {
			Home: {
				Line1: 'ஸ்டோரிகிராஃப்ட், பாரம்பரிய கதை சொல்லுதலின் மறு',
				Line2: 'மந்திரத்தின் உலகங்களுக்கான உங்கள் கதவு',
				CTAButton: 'ஒரு கதையை உருவாக்கவும்',
				PopularThisWeek: 'இந்த வாரம் பிரபலமான கதைகள்'
			},
			SetLang: {
				Title: 'Storycraft மொழ',
				Prompt: 'உங்கள் மொழியைத் தேர்ந்தெடுக்கவும்'
			},
			NavBar: {
				Library: 'நூலகம்',
				Write: 'எழுதுங்கள்',
				About: 'பற்றி',
				Language: 'மொழி'
			},
			Footer: {
				Copyright: 'பதிப்புரிமை © Capgemini 2023',
				Terms: 'சேவை விதிமுறைகள்',
				Privacy: 'Capgemini தனியுரிமை'
			},
			Cards: {
				Rules: 'விதிகளை உருவாக்குங்கள்',
				Rules2: 'இலக்கு வயது, வகை, நீளம் மற்றும் முக்கியமாக, உங்கள் கதையின் முக்கிய கருப்பொருளைத் தேர்வு செய்கிறீர்கள்.',
				Pull: 'அதை ஒன்றாக இழுக்கவும்',
				Pull2: 'கதை மற்றும் அதனுடன் கூடிய எடுத்துக்காட்டுகள் இரண்டையும் உருவாக்க ஜெனரேட்டிவ் AI ஐப் பயன்படுத்தி உங்கள் வழிகாட்டுதல்களைப் பயன்படுத்துகிறோம்',
				Magic: 'மந்திரத்தை அனுபவிக்கவும்',
				Magic2: 'எங்கள் பார்வையாளர் மூலம் உங்கள் கதையை அனுபவிக்கவும், PDF ஐ பதிவிறக்கவும் அல்லது எம்எல் மூலம் இயங்கும் உரை-டு-பேச்சை அதை உயிர்ப்பிக்கவும்.'
			},
			StoryCard: { ReadNow: 'இப்போது படியுங்கள்', Age: 'வயது' },
			Sponsors: {
				CreatedBy: 'ஸ்டோரிகிராஃப்ட் உருவாக்கப்பட்டது',
				BroughtBy: 'ஸ்டோரிகிராஃப்ட் கேப்ஜெமினியால் உங்களுக்குக் Capgemini வரப்பட்டு அமேசான் வலை சேவைகள் (AWS) மூலம் இயக்கப்படுகிறது.'
			},
			Library: {
				Title: 'நூலகம்',
				Search: 'தேடல்',
				SearchPlaceHolder: 'தலைப்பு அல்லது முக்கிய வார்த்தையை தேடுங்கள்',
				Age: 'வயது',
				Genre: 'வகை',
				Length: 'நீளம்',
				ByYou: 'உன்னால் எழுதப்பட்டது',
				Loading: 'நூலகம் ஏற்றுகிறது'
			},
			ReadStory: {
				Loading: 'கதையை ஏற்றுகிறது',
				DownloadPDF: 'PDF ஐ பதிவிறக்கவும்',
				TheEnd: 'முடிவு!',
				StoryBy: 'ஸ்டோரி கிராஃப்ட் சிட்னி Capgemini அணியால் வடிவமைக்கப்பட்டு கட்டப்பட்டது.',
				ImagesBy: 'அமேசான் பெட்ராக் உருவாக்கிய கதைகள் மற்றும் படங்கள்.',
				Page: 'பக்கம்',
				ReadPage: 'பக்கத்தை சத்தமாக படியுங்கள்',
				ReadStory: 'கதையை சத்தமாக படியுங்கள்',
				ReadStop: 'நிறுத்துங்கள்',
				StoryId: 'நீங்கள் ஸ்டோரி ஐடியைப் படிக்கிறீர்கள்',
				Link: 'பின்வரும் இணைப்பைப் பயன்படுத்தி நீங்கள் எந்த நேரத்திலும் இந்த கதைக்குத் திரும்பலாம்'
			},
			Write: {
				ErrorPrompt: 'நீங்கள் ஒரு விசாரணையை வழங்க வேண்டும்!',
				StateWriting: 'உங்கள் கதையை எழுதுதல்',
				StateReading: 'சத்தமாக படித்தல்',
				StateDrawing: 'கலை பெறுவது',
				Title: 'ஒரு புதிய கதையை எழுதுங்கள்',
				CraftingTitle: 'கைவினை கதை',
				FormAbout: 'கதை எதைப் பற்றி இருக்க வேண்டும்?',
				FormAboutHelp: 'உங்கள் கதை எதைப் பற்றி இருக்க வேண்டும்?',
				FormGenre: 'வகை என்றால் என்ன?',
				FormGenreHelp: 'நாம் என்ன வகையான கதையை உருவாக்க வேண்டும்?',
				FormLength: 'அது எவ்வளவு காலம் இருக்க வேண்டும்?',
				FormLengthHelp: 'குறுகியது 3-4 பக்கங்கள், நடுத்தர 5-6 பக்கங்கள் மற்றும் நீளம் 7-8 பக்கங்கள்',
				FormAge: 'வாசகரின் வயது எவ்வளவு?',
				FormAgeHelp: 'நீங்கள் ஒரு கதை எழுத விரும்பும் வயதைக் குறிப்பிடவும். இது உங்கள் வாசகருக்காக Storycraft மொழியுடன் சிறந்த கதையை எழுத ஸ்டோரிகிராஃப்டுக்கு உதவும்',
				FormButton: 'உங்கள் கதையை உருவாக்கவும்'
			},
			Login: {
				ErrorNoLogin: 'இந்த நேரத்தில் பயனரை உள்நுழைய முடியவில்லை',
				Title: 'ஸ்டோரி கிராஃப்ட் கணக்கு',
				LoggingIn: 'உள்நுழைதல்',
				UserKey: 'பயனர் விசை',
				ButtonLogin: 'நுழைவு',
				LoggedInAs: 'என உள்நுழைந்த',
				Credits: 'மீதமுள்ள கடன்கள்',
				ButtonLogout: 'உள்நுழைவு'
			},
			Register: {
				Error: 'இந்த நேரத்தில் பயனரை பதிவு செய்ய முடியவில்லை',
				Running: 'பயனரை பதிவு செய்தல்',
				Intro: '<p>\n' +
					' உங்கள் சொந்த கதைகளை எழுதுவதற்கு முன்பு நீங்கள் பதிவு செய்யப்பட வேண்டும். பதிவு செய்ய, தயவுசெய்து உங்கள் விவரங்களை கீழே கொடுங்கள். நீங்கள் பதிவு செய்த பிறகு, நீங்கள்\n' +
					' ஒரு கதை எழுத திருப்பி விடப்படும். இது உங்களுக்கு ஒரு கதை படைப்பை வழங்கும்.</p>\n' +
					' <p>\n' +
					' உங்கள் மின்னஞ்சல் முகவரியை நீங்கள் சரிபார்த்தவுடன், உங்களுக்கு மூன்று கூடுதல் கதை வரவுகள் வழங்கப்படும்.</p>',
				Name: 'முழு பெயர்',
				NameError: 'உங்கள் முழு பெயரை உள்ளிடவும்',
				Email: 'மின்னஞ்சல்',
				EmailError: 'சரியான மின்னஞ்சலை உள்ளிடவும்',
				Company: 'நிறுவனம்',
				CompanyError: 'செல்லுபடியாகும் நிறுவனத்தை உள்ளிடவும்',
				CheckBox: 'இந்த பெட்டியைச் சரிபார்ப்பதன் மூலம் நீங்கள் படித்திருப்பதை ஏற்றுக்கொள்கிறீர்கள் மற்றும் அதற்கேற்ப தகவல்களைப் பகிர்வதற்கு ஒப்புக்கொள்கிறீர்கள் \n' +
					' <a href="https://www.capgemini.com/privacy-notice/">கேப்கெமினி தனியுரிமைக் கொ</a> ள்கை மற்றும் உங்கள் \n' +
					' மேலே சுட்டிக்காட்டப்பட்டபடி மற்றும் Capgemini விதிமுறைகளில் விவரிக்கப்பட்டுள்ளபடி தரவு கேப்ஜெமினியால் <a href="https://www.capgemini.com/terms-of-use/">செயலாக்கப்படும்</a>',
				ButtonRegister: 'பதிவு',
				ButtonHome: 'முகப்பு',
				ThanksCredits: 'நன்றி, நீங்கள் இப்போது பதிவு செய்யப்பட்டிருக்கிறீர்கள் மற்றும் சோதனை வரவுகள் வழங்கப்பட்டுள்ளன. ஒவ்வொரு கடனும் ஒரு கதையை உருவாக்க பயன்படுத்தப்படலாம். உங்கள் மின்னஞ்சலை நீங்கள் இன்னும் சரிபார்க்கவில்லை என்றால், உங்கள் வரவேற்பு மின்னஞ்சலில் உள்ள இணைப்பைப் பயன்படுத்தி கூடுதல் வரவுகளைப் பெறலாம்.',
				ThanksNoCredits: 'ஸ்டோரிகிராஃப்ட் மீதான உங்கள் ஆர்வத்திற்கு நன்றி. உங்களிடம் எந்த கடனும் எஞ்சியிருக்கவில்லை. மேலும் கடன்களைக் கோர, தயவுசெய்து உங்கள் Capgemini ரெஸ்பென்டிட்டிவைத் தொடர்பு உங்கள் மின்னஞ்சலை நீங்கள் இன்னும் சரிபார்க்கவில்லை என்றால், உங்கள் வரவேற்பு மின்னஞ்சலில் உள்ள இணைப்பைப் பயன்படுத்தி கூடுதல் வரவுகளைப் பெறலாம்.',
				Title: 'ஒரு புதிய கதையை எழுதுங்கள்'
			},
			About: {
				Title: 'ஸ்டோரி கிராஃப்ட் பற்றி',
				Body: '<p>\n' +
					' ஸ்டோரிகிராஃப்ட் என்பது AWS உடன் இணைந்து ஆசியா பசிபிக் பிராந்தியம் முழுவதும் உள்ள Capgemini அலுவலகங்களில் இரண்டு மணி நேர ஹேக்கத்தோனில் இருந்து பிறந்த ஒரு \n' +
					' இந்த திட்டம் நவீன AI/ML ஐ கதை சொல்லும் காலமற்ற கலையுடன் இணைப்பதை நோக்கமாகக் கொண்டுள்ளது, இது போன்ற வயது வந்தோர் கருத்துக்களை மாற்றுவதற்கு தலைமுறை AI (GenAI) ஐப் பயன்படுத்தி \n' +
					' குழந்தைகளுக்கு ஏற்ற கதைகளாக நீர் சுழற்சி.</p>\n' +
					' <p>\n' +
					' ஒரு குழு உறுப்பினரின் தனது குழந்தைகளுக்கு கதை சொல்லும் அனுபவத்தால் ஈர்க்கப்பட்ட சிட்னி ஹேக்கத்தான் குழு, கதைகளை வடிவமைக்க GenAI ஐப் பயன்படுத்தி கருத்துரைக்கப்பட்டது \n' +
					' குழந்தைகளின் நலன்களுக்கு. குழந்தைகளை உற்சாகப்படுத்தும் கருத்துக்களைச் சுற்றி ஈர்க்கக்கூடிய கதைகளை உருவாக்க ஆசிரியர்கள் மற்றும் பராமரிப்பாளர்களுக்கு கருவிகளை வழங்குவதே \n' +
					' வெப்ப கடத்துத்திறனை ஆராயும் தீ லாரிகள் போன்றவை ஹேக்கத்தோனின் போது ஒரு வேலை செய்யும் முன்மாதிரியை உருவாக்குவதில் அணியின் வெற்றி அவர்கள் முதலில் வெற்றிக்கு வழிவகு \n' +
					' இடம், பரந்த உற்சாகத்தைத் தூண்டுகிறது மற்றும் இதன் விளைவாக தளத்தின் தற்போதைய உருவான பதிப்பு உருவாகிறது, இது அதே அடிப்படை AI தர்க்கத்தைத் தக்கவைத்துக்கொள்கிறது</p>\n' +
					' <p>\n' +
					' இறுதியில், இரண்டு முக்கிய எடுத்துக்கொள்ளல்கள் உள்ளன:\n' +
					' <ol>\n' +
					' <li>ஜெனரேட்டிவ் AI என்பது ஒரு சக்திவாய்ந்த கருவியாகும், இது ஒரு பொம்மை, ஒரு சாட்பாட் அல்லது பரிந்துரை இயந்திரத்தை விட அதிகம்.</li>\n' +
					' <li>எல்லா கருவிகளையும் போலவே, நீங்கள் சரியான ஒன்றைப் பயன்படுத்துகிறீர்கள் என்பதை உறுதிப்படுத்தவும். தீர்க்கப்பட வேண்டிய சிக்கலுடன் தொடங்கி சரியான கருவியைக் கண்டறியவும்.</li> </ol>\n' +
					' இங்கே, கேப்ஜெமினியில், நீங்கள் எதிர்கொள்ளும் சிக்கல்களைப் புரிந்துகொள்வதற்கும், சரியான தீர்வைக் கண்டுபிடிக்க உங்களுடன் பணியாற்றுவதற்கும் வாய்ப்பை நாங்கள் விரும்புவோம். <br />\n' +
					' மேலும் தகவலுக்கு, அல்லது உங்கள் ஜெனரல் AI தேவைகளை ஆராய, தயவுசெய்து ரிச்சர்ட் வில்கின <a href="mailto:richard.a.wilkinson@capgemini.com">்சனை அணுக</a></p> வும்.',
				Team: 'ஸ்டோரிகிராஃப்ட் சிட்',
				UI: 'பயனர் இடைமுகம் மற்றும் அனுபவம்',
				Executive: 'நிர்வாக ஸ்பான்சர்கள்',
				Design: 'சிஸ்டம் டிசைன்',
				Architecture: 'சிஸ்டம் கட்டமைப்பு',
				Flows1: 'பயனர் ஓட்டங்கள் 1',
				Flows2: 'பயனர் ஓட்டங்கள் 2',
				Generation: 'கதை தலைமுறை ஓட்டம்'
			}
		}
	}
];

export class i18n {
	public static GetLangCode(): string {
		//console.log("GetLangCode");
		let sessionLang = window.sessionStorage.getItem("sc-language");
		if ((sessionLang === undefined) || (sessionLang === null)) {
			//Get lang from browser
			const nav = (navigator as any);
			let navLang: string = nav.language || nav.userLanguage; 
			if (navLang.indexOf('-') > 0) {
				const bits = navLang.split('-');
				navLang = bits[0];
			}
			console.log(`Read browser default lang as ${navLang}`);
			sessionLang = this.SetLangCode(navLang);
		}
		return sessionLang;
	}

	public static SetLangCode(langCode: string): string {
		//console.log("SetLangCode - " + langCode);
		const sessionLang = this.ValidCode(langCode);
		console.log(`Setting session language to ${sessionLang}`);
		window.sessionStorage.setItem("sc-language", sessionLang);
		return sessionLang;
	}

	private static ValidCode(langCode: string): string {
		//console.log("ValidCode - " + langCode);
		const ld = langData.find(r => r.langCode === langCode);
		if (ld !== undefined) {
			//console.log("ValidCode - Is Valid");
			return langCode;
		} 
		//console.log("ValidCode - Invalid");
		return 'en';
	}

	public static GetLang(langCode: string | undefined = undefined) {
		//console.log("GetLang - " + langCode);
		let code = langCode;
		if (code === undefined) {
			code = i18n.GetLangCode();
		}
		//console.log("GetLang - Actual Code is " + code);
		let lang = langData.find(r => r.langCode === code);
		//console.log("Lang is =>");
		//console.log(lang);
		if (lang === undefined) {
			lang = langData[0];
		}
		return lang;
	}

	public static GetLangs(): Language[] {
		//console.log("GetLangs");
		return langData;
	}

	//Class stuff

	public lang: {[key: string]: string};
	public meta:  Translation;
	private langCode: string;
	private pageName: string;

	constructor(pageName: string) {
		this.pageName = pageName;
		this.langCode = i18n.GetLangCode();
		const l = i18n.GetLang(this.langCode);
		this.lang = l.translations[pageName];
		this.meta = l.meta;
	}

	/*
	public ChangeLang(langCode: string) {
		i18n.SetLangCode(langCode);
		this.langCode = langCode;
		const l = i18n.GetLang(langCode);
		this.lang = l.translations[this.pageName];
		this.genres = l.genres;
	}
*/

	public t(key: string): string {
		if (this.lang === undefined) {
			return "{{" + key + "}}";
		}
		const val = this.lang[key];
		if (val === undefined) {
			return "{{" + key + "}}";
		}
		return val;
	}

	public g(type: string, key: string): string {
		if ((this.meta === undefined) || (this.meta[type] === undefined)) {
			return "{{" + key + "}}";
		}
		const tSrc = this.meta[type];

		const val = tSrc[key];
		if (val === undefined) {
			return "{{" + key + "}}";
		}
		return val;
	}

}


