import React, { Component, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { User, UserAuth } from '../models';
import axios from 'axios';
import useDocumentTitle from '../useDocumentTitle';
import NavBar from '../components/navbar';
import "../styles/register.scss"
import Footer from '../components/footer';
import mysticalcreatures from '../images/mysticalCreatures.png'
import Spinner from '../components/spinner';
import { i18n } from '../i18n';


export default function Register() {
	const navigate = useNavigate();
	const [running, setRunning] = useState(false);
	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');
	const [privacyChecked, setPrivacyChecked] = useState(false);

	const [nameErr, setNameErr] = useState(false);
	const [emailErr, setEmailErr] = useState(false);
	const [companyErr, setCompanyErr] = useState(false);
	const [privacyCheckErr, setPrivacyCheckErr] = useState(false);
	
	const [user, setUser] = useState(new UserAuth());

	const l = new i18n("Register");
	const t = (key: string) => l.t(key);

	useDocumentTitle("Storycraft - New User Registration");


	const doSubmit = () => {
		console.log({ 'email': email, 'fullName': fullName, 'privacyChecked': privacyChecked })


		const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		if (fullName === "" || email === "" || company === "" || privacyChecked === false || !expression.test(email)) {
			if (fullName === "") {
				setNameErr(true)
			}
	
			if (email === "") {
				setEmailErr(true)
			}

			if (company === "") {
				setCompanyErr(true)
			}
	
			if (privacyChecked === false) {
				setPrivacyCheckErr(true)
			}
		
			if (!expression.test(email)) {
				setEmailErr(true)
			}

			return;
		}

		setRunning(true);
		axios.post('/api/create-user', { email: email, name: fullName, company: company, privacyChecked: true })
			.then(res => {
				setRunning(false);
				setUser(new UserAuth(res.data.key, res.data.name, res.data.credits));
			})
			.catch(err => {
				alert(t("Error"));
				setRunning(false);
			})
	}

	let body;

	if (running) {
		body = (
			<div className='loading-window'>
				<br />
				<Spinner size="5rem" />
				<br />
				<h2>{t("Running")}</h2>
			</div>
		);
	} else {
		if (!user.LoggedIn) {
			body = (
				<>
					<div dangerouslySetInnerHTML={{ __html: t("Intro") }}></div>
					<Form onSubmit={(e) => {
						e.preventDefault();
						doSubmit();
					}}>
						<Form.Group controlId="name" className='form'>
							<Form.Label>{t("Name")}: </Form.Label>
							<Form.Control type="text" id="name" value={fullName} onChange={(e) => {
								if (e.target.value) {
									setNameErr(false)
								}
								setFullName(e.target.value);
							}} />
							{nameErr && <Form.Text>{t("NameError")}</Form.Text>}
						</Form.Group><br />


						<Form.Group controlId="email" className='form'>
							<Form.Label>{t("Email")}: </Form.Label>
							<Form.Control type="email" id="email" value={email} onChange={(e) => {
								if (e.target.value) {
									setEmailErr(false)
								}
								setEmail(e.target.value);
							}} />
							{emailErr && <Form.Text>
								<div>{t("EmailError")}</div>
							</Form.Text>}
						</Form.Group><br />

						<Form.Group controlId="company" className='form'>
							<Form.Label>{t("Company")}: </Form.Label>
							<Form.Control type="company" id="company" value={company} onChange={(e) => {
								if (e.target.value) {
									setCompanyErr(false)
								}
								setCompany(e.target.value);
							}} />
							{companyErr && <Form.Text>
								<div>{t("CompanyError")}</div>
							</Form.Text>}
						</Form.Group><br />

						<Form.Group >
							<Form.Check
								type='checkbox'
								id={"privacyStatements"}
								label={<span dangerouslySetInnerHTML={{ __html: t("CheckBox") }}></span>}
								onChange={(e) => {
									if (e.target.checked === true) {
										setPrivacyCheckErr(false)
									}
									setPrivacyChecked(e.target.checked)
								}}
							/>
							{privacyCheckErr && <Form.Text>Please agree to the terms and conditions</Form.Text>}
						</Form.Group>

						<div style={{ textAlign: 'right', marginTop: 50 }} className="btn-container">
							<Button variant='secondary' onClick={doSubmit} >
								{t("ButtonRegister")}
							</Button>
							<Button className='home-btn' style={{ float: "left" }} onClick={() => {
								navigate("/");
							}} >{t("ButtonHome")}</Button>
						</div>
					</Form>
				</>
			);
		} else {
			if (user.Credits > 0) {
				body = (
					<>
						<p>
						{t("ThanksCredits")}
 						</p>
						<div className='text-center'>
							<Button variant='secondary' style={{ float: "none" }} onClick={() => { navigate("/write"); }} >Write Story</Button>
						</div>
					</>
				);
			} else {
				body = (
					<>
						<p>
							{t("ThanksNoCredits")}
						</p>
						<div className='text-center'>
							<Button variant='secondary' style={{ float: "none" }} onClick={() => { navigate("/"); }} >Home</Button>
						</div>
					</>
				);
			}
		}
	}


	return (
		<div>
			<NavBar />
			<div className='register-container'>
				<div className='outer-border'>
					<Card className="register-card" style={{ float: "left" }}>
						{!running && <Card.Header>
							<h2>{t("Title")}</h2></Card.Header>}
						<br />
						<img src={mysticalcreatures} height="60" alt='mystical creatures icons' />
						<br />
						<Card.Body className='description'>
							{body}
						</Card.Body>
					</Card>
				</div>

			</div>
			<Footer />
		</div>
	)
}

