import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import useDocumentTitle from '../useDocumentTitle';
import "../styles/home.scss"
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import StoryCraftCards from '../components/storycraft-cards/index'
import Sponsors from '../components/sponsors';
import StoryCard from '../components/storycard';
import storycraftBorder from '../images/storycraftBorder.png'
import storycraftBorderBottom from '../images/storycraftBorderBottom.png'

import tellAStory from '../images/tellAStory.png'
import background from '../images/background.png'
import { i18n } from '../i18n';

export default function Home() {
	const navigate = useNavigate();
	const [stories, setStories] = useState([]);
	
	const l = new i18n("Home");
	const t = (key: string) => l.t(key);

	useDocumentTitle("Storycraft - Engaging storytelling with Generative AI");

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

		axios.get('/api/get-homepage', {
			headers: {
					'sc-language': i18n.GetLangCode()
				}
			})
			.then(res => {
				setStories(res?.data?.Stories);
			})
			.catch(err => {
				console.error(err)
			});
	}, [])


	return (

		<div>
			<NavBar />
			<Container>

				{/* Home page part 1 */}
				<div className='home-container'>
					<div className='homepage'>
						<div className='homepage-text'>

							<p className='description'>
								{ t("Line1") }
							</p>
							<h1>
								{ t("Line2") }
							</h1>
							<Button variant="primary" size="lg" onClick={() => navigate("/write")} >{ t("CTAButton") }</Button>
							{/* <Button variant="info" size="lg" style={{ width: "30%", margin: "15px", padding: "20px"}} onClick={() => navigate("/read")} >Read existing story</Button> */}
						</div>

						<img src={background} className="home-cover" width="100%" alt='background mystic ' />
					</div>
				</div>

				{/* Home page part 2 */}

				<img src={storycraftBorder} width="100%" alt="border" />
				<StoryCraftCards />
				<img src={storycraftBorderBottom} width="100%" alt="border" />
				<div className='favorite-stories__container'>
					<div className='favorite-stories__header'>{ t("PopularThisWeek") }</div>
					<div className='favorite-stories__subheader'></div>
					<div className='scroll-container'>
						{stories.map((story) =>
							<StoryCard size="small" data={story} />)}
					</div>
				</div>

				{/* Home page part 3 */}
				<Sponsors />

				{/* Home page part 4 */}
			</Container>
			<Footer />
		</div>
	)
}


/*<div className='tell-a-story'>
					<div className='btn-container'>
						<Button variant='tertiary' onClick={() => navigate("/write")}>Click here</Button>
					</div>
					<img src={tellAStory} alt='Ready to tell a story' />
				</div>
				*/