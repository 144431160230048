import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
// import './bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/home';
import ReadBook from './pages/readbook';
import Write from './pages/write';

import ReactGA from "react-ga4";
import Register from './pages/register';
import Library from './pages/library';
import About from './pages/about';
import Login from './pages/login';
import SetLang from './pages/set-lang';

ReactGA.initialize("G-8WX20LV0R6");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
	},
	{ 
		path: "library",
		element: <Library/>
	},
	{ 
		path: "about",
		element: <About/>
	},
	{ 
		path: "login",
		element: <Login />
	},
	{ 
		path: "read/:storyid",
		element: <ReadBook />
	},
	{ 
		path: "write",
		element: <Write />
	},
	{
		path: "register",
		element: <Register/>
	},
	{
		path: "set-lang",
		element: <SetLang />
	}
]);

root.render(
    <RouterProvider router={router} />
);

//<React.StrictMode>
//</React.StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
