import React from "react";
import "./styles.scss";
import capg from "../../images/svg/capg.svg";
import aws from "../../images/svg/aws.svg";
import profileIcon from "../../images/profile-icon-v3.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { i18n } from '../../i18n';

const NavBar = () => {
	const l = new i18n("NavBar");
	const t = (key) => l.t(key);

  const navigate = useNavigate();

  return (
    <div className="navigation-bar__container">
      <div className="left">
        <div className="storycraft-title" onClick={() => navigate("/")}>
          Storycraft
        </div>
        <div className="vertical-line"></div>
        <div className="logo-container">
          <Button
            className="icon-btn"
            onClick={() => {
              window.open("https://www.capgemini.com/solutions/cloud-with-aws/", "_blank");
						}}
          >
            <img src={capg} alt="capgemini-logo" />
          </Button>
          <Button
            className="icon-btn"
            onClick={() => {
							window.open("https://partners.amazonaws.com/partners/001E000000Qcj4uIAB/Capgemini", "_blank");
						}}
          >
            <img src={aws} alt="aws-logo" />
          </Button>
        </div>
      </div>
      <div className="right">
        <a className="navbar__menu-heading" href="/library">
          { t("Library") }
        </a>
        <a className="navbar__menu-heading" href="/write">
					{ t("Write") }
        </a>
        <a className="navbar__menu-heading" href="/about">
				{ t("About") }
        </a>
				<a className="navbar__menu-heading" href="/set-lang">
				{ t("Language") }
        </a>
        <div className="navbar__menu-profile-icon">
					<a className="navbar__menu-heading" href="/login">
          	<img src={profileIcon} alt="aws-logo" />
					</a>
				</div>
      </div>
    </div>
  );
};

export default NavBar;
