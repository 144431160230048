import React from 'react'
import './styles.scss'

const Spinner = ({ size }) => {
    return (
        <div className='spinner-border' style={{
            "--storycraft-spinner-width": size,
            "--storycraft-spinner-height": size
        }}></div>
    )
}

export default Spinner